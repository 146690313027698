let body = document.querySelector("body");
let lgDesktopSize = window.matchMedia("(min-width: 1920px)");
let desktopSize = window.matchMedia("(min-width: 1200px) and (max-width: 1920px)");
let tabletSize = window.matchMedia("(min-width: 600px) and (max-width: 1200px)");
let mobileSize = window.matchMedia("(max-width: 600px)");



/************    flow logo animation on scroll     ***********/


let flowWrapper = document.querySelector(".flow-wrapper");
let glasgowLogo = document.querySelector("#glasgow-school-of-art");
let flowHeroLogo = document.querySelector("#flow-hero-logo");
let cop26Logo = document.querySelector("#cop26-hero-logo");
let flowLine = document.querySelector("#flow-line");
let flowWrapperHeader = document.querySelector(".flow-wrapper-in-header");


function heroScrollFunction() {

    if ((window.pageYOffset > 100) && (desktopSize.matches)) {
        glasgowLogo.style.display = "none";
        flowHeroLogo.style.width = "120px";
        cop26Logo.style.fontSize = "2.2em";
        flowLine.style.display = "block";
        flowWrapper.classList.add("flow-wrapper-slide");
    } else if ((window.pageYOffset < 100) && (desktopSize.matches)) {
        flowWrapper.style.transition = "0.8s";
        glasgowLogo.style.display = "block";
        flowHeroLogo.style.width = "540px";
        cop26Logo.style.fontSize = "5em";
        flowLine.style.display = "none";
        flowWrapper.classList.remove("flow-wrapper-slide");
    } else if ((window.pageYOffset > 100) && (lgDesktopSize.matches)) {
        flowWrapper.style.transition = "0.8s";
        glasgowLogo.style.display = "none";
        flowHeroLogo.style.width = "120px";
        cop26Logo.style.fontSize = "2.2em";
        flowLine.style.display = "block";
        flowWrapper.classList.add("flow-wrapper-slide");
    } else if ((window.pageYOffset < 100) && (lgDesktopSize.matches)) {
        flowWrapper.style.transition = "0.8s";
        glasgowLogo.style.display = "block";
        flowHeroLogo.style.width = "1020px";
        cop26Logo.style.fontSize = "5em";
        flowLine.style.display = "none";
        flowWrapper.classList.remove("flow-wrapper-slide");
    }
    /*
         else if ((window.pageYOffset > 100) && (tabletSize.matches)) {
            glasgowLogo.style.display = "none";
            flowWrapperHeader.style.display = "flex";
        } else if (tabletSize.matches) {
            glasgowLogo.style.display = "block";
            flowWrapperHeader.style.display = "none";
        } else if ((window.pageYOffset > 100) && (mobileSize.matches)) {
            glasgowLogo.style.display = "none";
        } else {
            glasgowLogo.style.display = "block";
        }
        */
}


/*************    sidebar slide-in   ***************/


let sidebar = document.querySelector(".sidebar");
let sidebarMenu = document.querySelector(".sidebar-menu");
let sidebarCarousel = document.querySelector(".sidebar-carousel-container");

let sidebarIcon = document.querySelector(".sidebar-eye");
let sidebarIconHover = document.querySelector(".sidebar-eye-hover");

let sidebarIconOpen = document.querySelector(".sidebar-eye-open");
let sidebarIconHoverOpen = document.querySelector(".sidebar-eye-hover-open");
let sidebarIconWhite = document.querySelector(".sidebar-eye-white");

let instaIcons = document.querySelector(".insta-icons");
let instaIcon = document.querySelector(".insta-icon");
let instaIconHover = document.querySelector(".insta-icon-hover");

let twitterIcons = document.querySelector(".twitter-icons");
let twitterIcon = document.querySelector(".twitter-icon");
let twitterIconHover = document.querySelector(".twitter-icon-hover");



/*****  social media icons ******/

instaIcons.addEventListener("mouseover", function () {
    instaIcon.classList.add("hide");
    instaIconHover.classList.add("show");
});

instaIcons.addEventListener("mouseout", function () {
    instaIcon.classList.remove("hide");
    instaIconHover.classList.remove("show");
});

twitterIcons.addEventListener("mouseover", function () {
    twitterIcon.classList.add("hide");
    twitterIconHover.classList.add("show");
});

twitterIcons.addEventListener("mouseout", function () {
    twitterIcon.classList.remove("hide");
    twitterIconHover.classList.remove("show");
});




/*****     sidebar icon    *****/



if (!mobileSize.matches) {
    sidebarIcon.addEventListener("mouseover", function () {
        sidebarIcon.classList.add("hide");
        sidebarIconHover.classList.add("show");
    });

    sidebarIconHover.addEventListener("mouseout", function () {
        sidebarIcon.classList.remove("hide");
        sidebarIconHover.classList.remove("show");
    });

    sidebarIconHover.addEventListener("click", function showSidebar() {
        sidebarMenu.style.transition = "1s";
        sidebarMenu.classList.add("sidebar-menu-slide");
        sidebarCarousel.style.transition = "1.5s";
        sidebarCarousel.classList.add("sidebar-carousel-slide");
        setTimeout(function () {
            document.body.style.overflowY = "hidden";
        }, 600);
    });
    sidebarIconOpen.addEventListener("mouseover", function () {
        sidebarIconOpen.classList.add("hide");
        sidebarIconHoverOpen.classList.add("show");
    });

    sidebarIconHoverOpen.addEventListener("mouseout", function () {
        sidebarIconOpen.classList.remove("hide");
        sidebarIconHoverOpen.classList.remove("show");
    });

    sidebarIconHoverOpen.addEventListener("click", function showSidebar() {
        sidebarMenu.style.transition = "1.5s";
        sidebarMenu.classList.remove("sidebar-menu-slide");
        sidebarCarousel.style.transition = "1s";
        sidebarCarousel.classList.remove("sidebar-carousel-slide");
        document.body.style.overflowY = "visible";
    });
} else {
    sidebarIcon.addEventListener("click", function showSidebar() {
        sidebarMenu.style.transition = "1s";
        sidebarMenu.classList.add("sidebar-menu-slide");
        sidebarCarousel.style.transition = "1.5s";
        sidebarCarousel.classList.add("sidebar-carousel-slide");
        setTimeout(function () {
            document.body.style.overflowY = "hidden";
        }, 600);
    });

    sidebarIconOpen.addEventListener("click", function showSidebar() {
        sidebarMenu.style.transition = "1.5s";
        sidebarMenu.classList.remove("sidebar-menu-slide");
        sidebarCarousel.style.transition = "1s";
        sidebarCarousel.classList.remove("sidebar-carousel-slide");
        document.body.style.overflowY = "visible";
    });
}

sidebarIconWhite.addEventListener("click", function showSidebar() {
    sidebarMenu.style.transition = "1s";
    sidebarMenu.classList.add("sidebar-menu-slide");
    sidebarCarousel.style.transition = "1.5s";
    sidebarCarousel.classList.add("sidebar-carousel-slide");
    setTimeout(function () {
        document.body.style.overflowY = "hidden";
    }, 600);
});






/*************    sidebar menu hover effects   ***************/

let sidebarMenuItems = document.querySelectorAll(".sidebar-menu-item");
let closeOfDiv = document.querySelector(".close-of-div");


if (!tabletSize.matches) {

    sidebarMenuItems.forEach(function (el) {
        el.addEventListener("mouseover", () => {
            el.firstElementChild.style.position = 'static';
            el.firstElementChild.style.visibility = 'visible';
            el.firstElementChild.style.marginRight = '16px';
            el.firstElementChild.style.transition = "0.5s";
        });

        el.addEventListener("mouseout", () => {
            el.firstElementChild.style.position = 'absolute';
            el.firstElementChild.style.visibility = 'hidden';
            el.firstElementChild.style.marginRight = '0px';
            el.firstElementChild.style.transition = "0s";
        });

        el.parentElement.addEventListener("click", () => {
            sidebarMenu.style.transition = "2.5s";
            sidebarCarousel.style.transition = "1s";
            setTimeout(() => {
                sidebarMenu.classList.remove("sidebar-menu-slide");
                sidebarCarousel.classList.remove("sidebar-carousel-slide");
            }, 1000);
            document.body.style.overflowY = "visible";
        });
    });

} else {
    sidebarMenuItems.forEach(function (el) {
        el.parentElement.addEventListener("click", () => {
            sidebarMenu.style.transition = "1.5s";
            sidebarCarousel.style.transition = "1s";
            setTimeout(() => {
                sidebarMenu.classList.remove("sidebar-menu-slide");
                sidebarCarousel.classList.remove("sidebar-carousel-slide");
            }, 1000);
            document.body.style.overflowY = "visible";
        });
    });
}




/*************   main menu hover animations   ***************/


let mainMenuSection = document.querySelector(".main-menu-section");
let mainMenuItemTexts = document.querySelectorAll(".main-menu-item-text");

let mainMenuItem1 = document.querySelector(".main-menu-item-text-1");
let mainMenuItem2 = document.querySelector(".main-menu-item-text-2");
let mainMenuItem3 = document.querySelector(".main-menu-item-text-3");
let mainMenuItem4 = document.querySelector(".main-menu-item-text-4");
let mainMenuItem5 = document.querySelector(".main-menu-item-text-5");
let mainMenuItem6 = document.querySelector(".main-menu-item-text-6");

let mainBgDiv1 = document.querySelector(".main-background-div-1");
let mainBgDiv2 = document.querySelector(".main-background-div-2");
let mainBgDiv3 = document.querySelector(".main-background-div-3");
let mainBgDiv4 = document.querySelector(".main-background-div-4");
let mainBgDiv5 = document.querySelector(".main-background-div-5");
let mainBgDiv6 = document.querySelector(".main-background-div-6");


if (!mobileSize.matches) {

    mainMenuItemTexts.forEach(function (el) {
        el.addEventListener("mouseover", () => {
            el.style.transition = "0.2s";
            el.previousElementSibling.style.position = 'static';
            el.previousElementSibling.style.visibility = 'visible';
            el.previousElementSibling.style.transition = "0.5s";
        });


        el.addEventListener("mouseout", () => {
            el.style.transition = "0.5s";
            el.previousElementSibling.style.position = 'absolute';
            el.previousElementSibling.style.visibility = 'hidden';
            el.previousElementSibling.style.transition = "0s";
        });
    });

    mainMenuItem1.addEventListener("mouseover", () => {
        mainBgDiv1.style.display = "block";
    });

    mainMenuItem1.addEventListener("mouseout", () => {
        mainBgDiv1.style.display = "none";
    });

    mainMenuItem2.addEventListener("mouseover", () => {
        mainBgDiv2.style.display = "block";
    });

    mainMenuItem2.addEventListener("mouseout", () => {
        mainBgDiv2.style.display = "none";
    });

    mainMenuItem3.addEventListener("mouseover", () => {
        mainBgDiv3.style.display = "block";
    });

    mainMenuItem3.addEventListener("mouseout", () => {
        mainBgDiv3.style.display = "none";
    });

    mainMenuItem4.addEventListener("mouseover", () => {
        mainBgDiv4.style.display = "block";
    });

    mainMenuItem4.addEventListener("mouseout", () => {
        mainBgDiv4.style.display = "none";
    });

    mainMenuItem5.addEventListener("mouseover", () => {
        mainBgDiv5.style.display = "block";
    });

    mainMenuItem5.addEventListener("mouseout", () => {
        mainBgDiv5.style.display = "none";
    });

    mainMenuItem6.addEventListener("mouseover", () => {
        mainBgDiv6.style.display = "block";
    });

    mainMenuItem6.addEventListener("mouseout", () => {
        mainBgDiv6.style.display = "none";
    });
} else {
    mainMenuItemTexts.forEach(function (el) {
        el.style.fontSize = "20px";
    });
}




/***********   welcome logo animation on scroll    ***********/


let welcomeSection = document.querySelector("#welcome-section");
let welcomeImg = document.querySelector(".welcome-img");
let allCreditsText = document.querySelectorAll(".credits-text");
let creditParagOdd = document.querySelectorAll(".credits-text:nth-child(odd)");
let creditParagEven = document.querySelectorAll(".credits-text:nth-child(even)");
let creditsHeading = document.querySelector(".credits-heading");

function welcomeScrollFunction() {

    if (((window.pageYOffset > 1300) && (desktopSize.matches)) || ((window.pageYOffset > 1800) && (lgDesktopSize.matches))) {
        welcomeImg.style.width = "35%";
        welcomeImg.style.position = "absolute";
        welcomeImg.style.left = "9%";
        welcomeImg.style.top = "3%";
    }

    if (((window.pageYOffset > 2200) && (desktopSize.matches)) || ((window.pageYOffset > 3000) && (lgDesktopSize.matches))) {

        creditsHeading.classList.add("fade-in");

        creditParagOdd.forEach(function (el) {
            el.classList.add("slideInLeft");
        });

        creditParagEven.forEach(function (el) {
            el.classList.add("slideInRight");
        });
    } else if ((window.pageYOffset > 1800) && (tabletSize.matches)) {
        creditsHeading.classList.add("fade-in");
        allCreditsText.forEach(function (el) {
            el.style.visibility = "visible";
        });
    } else if ((window.pageYOffset > 1200) && (mobileSize.matches)) {
        creditsHeading.classList.add("fade-in");
        allCreditsText.forEach(function (el) {
            el.style.visibility = "visible";
        });
    }
};

window.addEventListener("scroll", () => {
    heroScrollFunction();
    welcomeScrollFunction();
});


/*

if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
    if (window.pageYOffset > 100) {
        flowWrapper.style.transition = "0s";
    } else {
        flowWrapper.style.transition = "0.8s";
    }
}
*/






/**********     INTERSECTION OBSERVERS     *******************/


let firstInnerSection = document.querySelector(".first-intersection");
let closeOfPlayBlackBg = document.querySelector("#in-process-row");
let closeOfMainSection = document.querySelector(".close-of-main-section");
let closeOfSection = document.querySelector("#close-of-play-subsection");
let closeOfPlay1 = document.querySelector(".close-of-play-anim-1");
let closeOfPlay2 = document.querySelector(".close-of-play-anim-2");
let closeOfText = document.querySelector(".close-of-play-text");
let closeOfBrackets = document.querySelector(".close-brackets");
let closeOfBracketsImg = document.querySelector(".close-brackets-img");

const videoLogoOptions = {
    rootMargin: "-300px 0px -300px 0px"
};

const mainMenuSectionObserver = new IntersectionObserver(function (
    entries,
    mainMenuSectionObserver
) {
    entries.forEach(entry => {
        if (entry.isIntersecting && desktopSize.matches) {
            mainMenuItemTexts.forEach(function (el) {
                el.style.transition = "1.5s";
                el.style.fontSize = "24px";
                el.addEventListener("mouseover", () => {
                    el.style.fontSize = "28px";
                    el.style.fontWeight = "600";
                });
                el.addEventListener("mouseout", () => {
                    el.style.fontSize = "24px";
                    el.style.fontWeight = "400";
                });
            });
        } else if (entry.isIntersecting && lgDesktopSize.matches) {
            mainMenuItemTexts.forEach(function (el) {
                el.style.transition = "1.5s";
                el.style.fontSize = "36px";
                el.addEventListener("mouseover", () => {
                    el.style.fontSize = "40px";
                    el.style.fontWeight = "600";
                });
                el.addEventListener("mouseout", () => {
                    el.style.fontSize = "36px";
                    el.style.fontWeight = "400";
                });
            });
        };
    }, videoLogoOptions);
});

mainMenuSectionObserver.observe(mainMenuSection);



/****   close of play animation   ****/

const closeOfMainSectionObserver = new IntersectionObserver(function (
    entries,
    closeOfMainSectionObserver
) {
    entries.forEach(entry => {
        let animNode = closeOfSection.querySelector('.animation-wrapper');
        let closeOfBrackets = document.querySelector(".close-brackets");
        let closeOfBracketsImg = document.querySelector(".close-brackets-img");

        animNode.classList.remove('start');

        if ((entry.isIntersecting) && (!mobileSize.matches)) {
            closeOfBrackets.style.filter = "filter: brightness(0) invert(1)";
            closeOfBrackets.style.zIndex = "2";
            closeOfBracketsImg.style.width = "120px";
            animNode.classList.add("start");
        } else if ((entry.isIntersecting) && (mobileSize.matches)) {
            closeOfBrackets.style.filter = "filter: brightness(0) invert(1)";
            closeOfBrackets.style.zIndex = "2";
            closeOfBracketsImg.style.width = "60px";
            animNode.classList.add("start");
        } else {
            closeOfBracketsImg.style.width = "0px";
        }
    });
}, videoLogoOptions);

closeOfMainSectionObserver.observe(closeOfMainSection);




/****   close brackets turn white at black background-color  *****/

const inProcessRoot = {
    // rootMargin: "-600px 0px -50px 0px"
    rootMargin: "0px 0px -600px 0px"
};

const firstInnerObserver = new IntersectionObserver(function (
    entries,
    firstInnerObserver
) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            flowWrapper.style.filter = "brightness(0) invert(1)";
            closeOfBrackets.style.filter = "brightness(0) invert(1)";
            sidebarIcon.style.display = "none";
            sidebarIconWhite.style.display = "block";
        } else {
            flowWrapper.style.filter = "brightness(1) invert(0)";
            closeOfBrackets.style.filter = "brightness(1) invert(0)";
            sidebarIcon.style.display = "block";
            sidebarIconWhite.style.display = "none";
        }
    });
}, inProcessRoot);

firstInnerObserver.observe(firstInnerSection);

const inProcessObserver = new IntersectionObserver(function (
    entries,
    inProcessObserver
) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            flowWrapper.style.filter = "brightness(0) invert(1)";
            closeOfBrackets.style.filter = "brightness(0) invert(1)";
            sidebarIcon.style.display = "none";
            sidebarIconWhite.style.display = "block";
        } else {
            flowWrapper.style.filter = "brightness(1) invert(0)";
            closeOfBrackets.style.filter = "brightness(1) invert(0)";
            sidebarIcon.style.display = "block";
            sidebarIconWhite.style.display = "none";
        }
    });
}, inProcessRoot);

inProcessObserver.observe(closeOfPlayBlackBg);




/**********    figcaption appears while clicking & hovering over plus icon     ************/


let plusIcons = document.querySelectorAll(".plus-icon");

plusIcons.forEach(function (el) {

    let figCaption = el.parentElement.querySelector("span");


    el.addEventListener("mouseover", () => {

        if (figCaption.classList.contains("stretch-figcaption")) {
            el.addEventListener("click", () => {
                figCaption.classList.remove("stretch-figcaption");
                figCaption.classList.remove("show-figcaption");
            });
        } else {
            figCaption.classList.add("show-figcaption");
            el.addEventListener("click", () => {
                figCaption.classList.add("stretch-figcaption");
            });
        }
    });

    el.addEventListener("mouseout", () => {
        if (!figCaption.classList.contains("stretch-figcaption")) {
            figCaption.classList.remove("show-figcaption");
        }
    });
});


/*************    back to top button   *************/

//Get the button:
let backToTop = document.querySelector("#back-to-top");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
    scrollFunction()
};

function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        backToTop.style.display = "block";
    } else {
        backToTop.style.display = "none";
    }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}
